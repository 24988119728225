import { useEffect, useState } from 'react';
import s from './Report.module.scss';
import { DayPickerStrings } from '../../constants';
import moment from 'moment';
import {
  Modal,
  DatePicker,
  DayOfWeek,
  DefaultButton,
  PrimaryButton,
  TextField,
  TimePicker
} from '@fluentui/react';
import { SimpLoader } from 'components/Loader/Loader';
import { getShouldCheckExpDate } from 'redux/App/appSelectors';

const SendPrintReportModal = ({ isOpen, reports, onDismiss, onSubmit }) => {
  const [data, setData] = useState(reports);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setData((p) =>
      p.map((rep) => {
        const date = new Date(rep.expirationDate);
        return {
          ...rep,
          expirationDate: new Date(rep.expirationDate).setHours(
            date.getHours(),
            date.getMinutes(),
            date.getSeconds()
          ),
          expirationDateTime: [date.getHours(), date.getMinutes(), date.getSeconds()]
        };
      })
    );
  }, []);

  const handleSubmit = async () => {
    setLoading(true);
    await onSubmit(data);
    console.log(data);
    setLoading(false);
    onDismiss();
  };
  const onChange = (id, name) => (e, date) => {
    if (name === 'productCreationDate') {
      if (e === null) {
        setData((p) => {
          return p.map((rep) => {
            if (rep.id === id) {
              return { ...rep, productCreationDate: null };
            }
            return rep;
          });
        });
      } else {
        setData((p) => {
          return p.map((rep) => {
            if (rep.id === id) {
              return { ...rep, productCreationDate: e.getTime() };
            }
            return rep;
          });
        });
      }
    }
    if (name === 'expirationDate') {
      if (e === null) {
        setData((p) => {
          return p.map((rep) => {
            if (rep.id === id) {
              return { ...rep, expirationDate: null };
            }
            return rep;
          });
        });
      } else {
        setData((p) => {
          return p.map((rep) => {
            if (rep.id === id) {
              const expDate = rep?.expirationDateTime
                ? e.setHours(
                    rep.expirationDateTime[0],
                    rep.expirationDateTime[1],
                    rep.expirationDateTime[2]
                  )
                : e.getTime();
              return { ...rep, expirationDate: expDate };
            }
            return rep;
          });
        });
      }
    }
    if (name === 'expirationDateTime') {
      setData((p) => {
        return p.map((rep) => {
          if (rep.id.toString() === id.toString()) {
            return {
              ...rep,
              expirationDate: new Date(rep.expirationDate).setHours(
                date.getHours(),
                date.getMinutes(),
                date.getSeconds()
              ),
              expirationDateTime: [date.getHours(), date.getMinutes(), date.getSeconds()]
            };
          }
          return rep;
        });
      });
    }
  };

  return (
    <Modal containerClassName={s.modal} isOpen={isOpen} onDismiss={onDismiss} isBlocking={false}>
      {loading ? <SimpLoader size={'5rem'} /> : null}
      <main className={s.modalMainWrapper}>
        <span className={s.modTitle}>
          Проверьте корректность дат производства и окончания срока годности и скорректируйте при
          необходимости
        </span>
        <section className={s.columnTitles}>
          <span className={s.cell}>номер отчета</span>
          <span className={s.cell}>номер задания</span>
          <span className={s.cell}>дата производства</span>
          <span className={s.expCell}>дата срока годности</span>
        </section>
        <section className={s.formMainWrap}>
          {data.map((report) => (
            <DateForm report={report} onChange={onChange} />
          ))}
        </section>

        <section className={s.buttonRow}>
          <PrimaryButton className={s.btn} text={'Отправить'} onClick={handleSubmit} />
          <DefaultButton className={s.btn} text={'Отмена'} onClick={onDismiss} />
        </section>
      </main>
    </Modal>
  );
};

export default SendPrintReportModal;

const DateForm = ({ report, onChange }) => {
  return (
    <section className={s.rowWrapper}>
      <div className={s.cell}>
        <TextField value={report.id} disabled={true} className={s.textField} />
      </div>
      <div className={s.cell}>
        <TextField value={report.partNumber} disabled={true} className={s.textField} />
      </div>

      <DatePicker
        placeholder="Дата производства"
        className={s.date}
        firstDayOfWeek={DayOfWeek.Monday}
        allowTextInput={true}
        formatDate={(date) => {
          return date.getFullYear() === 1970 ? null : moment(date).format('DD-MM-YYYY');
        }}
        strings={DayPickerStrings}
        defaultValue={'Выберете дату производства'}
        value={new Date(report.productCreationDate)}
        onSelectDate={onChange(report.id, 'productCreationDate')}
      />

      <div className={s.expCell}>
        <DatePicker
          placeholder="Дата срока годности"
          className={s.date}
          firstDayOfWeek={DayOfWeek.Monday}
          allowTextInput={true}
          formatDate={(date) => {
            return date.getFullYear() === 1970 ? null : moment(date).format('DD-MM-YYYY');
          }}
          strings={DayPickerStrings}
          defaultValue={'Выберете дату срока годности'}
          value={new Date(report.expirationDate)}
          onSelectDate={onChange(report.id, 'expirationDate')}
        />

        <TimePicker
          showSeconds
          allowFreeform
          autoComplete="on"
          dateAnchor={new Date(report.expirationDate)}
          defaultValue={new Date(report.expirationDate)}
          onChange={onChange(report.id, 'expirationDateTime')}
        />
      </div>
    </section>
  );
};
