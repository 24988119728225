import clsx from 'clsx';
import {
  Checkbox,
  DatePicker,
  DayOfWeek,
  Dropdown,
  Stack,
  TextField
} from 'office-ui-fabric-react';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import consignmentsAPI from '../../../api/consignmentsAPI';
import linesAPI from '../../../api/linesAPI';
import productsAPI from '../../../api/productsAPI';
import g from '../../../assets/scss/Main.module.scss';
import { Alert } from 'components/Alert/Alert';
import { BottomButtons } from 'components/BottomButtons/BottomButtons';
import { GrayPanel } from 'components/GrayPanel/GrayPanel';
import { Loader } from 'components/Loader/Loader';
import { consignmentsForDropdown, DayPickerStrings } from '../../../constants';
import { useCrumbsContext } from '../../../context/CrumbsContext';
import { PreliminaryPrintForm } from '../PreliminaryPrintForm';
import {
  getDateFromTimestamp,
  mapLinesForDropdown,
  mapProductsForDropdown,
  validateField,
  validateForm
} from '../../../functions';
import { getPrintersAction } from 'redux/Printers/PrintersReducer';
import { useAlert, useSelect, useSendRequest } from '../../../hooks';
import s from './Consignment.module.scss';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { ExtraConsignmentFields } from './ConsignmentExtraInfo';
import DeleteTestConsigment from './deleteTestCons';
import ComboSelect from 'components/Forms/customSelect/comboSelect';
import { getGeneralSettings } from 'redux/App/appSelectors';
import { setGenSettings } from 'redux/App/appReducer';
import settingsAPI from 'api/settingsAPI';

const emptyConsignment = {
  taskNumber: '',
  codesQuantityFirstLevel: 0,
  product: null,
  lineNumber: null,
  consignmentCreationDate: null,
  consignmentStartDate: null,
  consignmentEndDate: null,
  duration: 0,
  vsd: '',
  productCreationDate: null,
  productPackingDate: null,
  plannedValue: 0,
  actualValue: 0,
  licenseNumber: '',
  dateOfIssue: '',
  isPreviousDate: false,
  status: 0,
  certificateType: null,
  certificateNumber: '',
  certificateDate: null,
  serialNumber: null,
};

const consignmentKeys = new Map([
  ['consignmentCreationDate', { name: 'Дата создания задания', type: 'date', edited: false }],
  ['consignmentStartDate', { name: 'Дата начала задания', type: 'date', edited: false }],
  ['consignmentEndDate', { name: 'Дата закрытия задания', type: 'date', edited: false }],
  ['vsd', { name: 'Ветеринарный сопроводительный документ', type: 'text', edited: true }],
  [
    'productCreationDate',
    {
      name: 'Дата производства продукта',
      type: 'date',
      edited: true,
      required: true
    }
  ],
  [
    'productPackingDate',
    {
      name: 'Дата упаковки продукта',
      type: 'date',
      edited: true,
      required: true
    }
  ],
  ['duration', { name: 'Продолжительность задания', type: 'number', edited: false }],
  ['plannedValue', { name: 'Плановое количество', type: 'number', edited: true }],
  ['actualValue', { name: 'Фактическое количество', type: 'number', edited: false }],
  [
    'isPreviousDate',
    {
      name: 'Возможность реализовывать прошедшей датой',
      type: 'checkbox',
      edited: true
    }
  ],
  ['taskNumber', { name: 'Номер задания', type: 'string', edited: true, required: true }],
  ['codesQuantityFirstLevel', { name: 'Количество КМ в коробе', type: 'number', edited: true }],
  ['unitWeight', { name: 'Масса короба', type: 'number', edited: true, required: true }],
  [
    'licenseNumber',
    { name: 'Номер лицензии на пользование недрами', type: 'string', edited: true }
  ],
  ['dateOfIssue', { name: 'Дата выдачи лицензии', type: 'date', edited: true }],
  ['serialNumber', { name: 'Серия', type: 'string', edited: true, required: true }]
]);

const generateSubsoilUseLicensesField = (conosigmentObject) => {
  conosigmentObject.subsoilUseLicenses = [];
  const licenseObject = {};
  licenseObject.licenseNumber = conosigmentObject.licenseNumber;
  licenseObject.dateOfIssue = conosigmentObject.dateOfIssue;
  licenseObject.serialNumber = conosigmentObject.serialNumber;

  if (licenseObject.licenseNumber && licenseObject.dateOfIssue) {
    conosigmentObject.subsoilUseLicenses.push(licenseObject);
  }
  delete conosigmentObject.licenseNumber;
  delete conosigmentObject.dateOfIssue;
  return conosigmentObject;
};

// *********** COMPONENT START *******************************

const getLineName = (lines, lineId) => {
  const rightLine = lines.find((line) => line.id.toString() === lineId.toString());
  if (rightLine) {
    return rightLine.label;
  }
  return '';
};

export const Consignment = () => {
  const { setCrumbs } = useCrumbsContext();
  const { consignmentId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [consignment, setConsignment] = useState(emptyConsignment);
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState('edit');
  const [consignmentErrors, setConsignmentErrors] = useState(new Set([]));
  const { alertTitle, alertText, isAlertShowed, showAlert, hideAlert } = useAlert();
  const { afterSending } = useSendRequest(setLoading);

  const [isLoading, setIsLoading] = useState(false);

  const [products, getProducts] = useSelect(
    productsAPI.getProductsByLineId,
    null,
    mapProductsForDropdown
  );
  const [lines, getLines] = useSelect(linesAPI.getAllLines, null, mapLinesForDropdown);

  const getConsignment = async (_consignmentId) => {
    setLoading(true);
    const res = await consignmentsAPI.getConsignmentById(_consignmentId);

    if (!res?.statusCode) setConsignment(res);
    setLoading(false);
    return res;
  };

  const getGenralSettings = async () => {
    setIsLoading(true);
    try {
      const res = await settingsAPI.getGeneralSettings();

      console.log(
        res?.specifySeriesWhenCreatingShiftTask,
        'Полученные настройки specifySeriesWhenCreatingShiftTask '
      );
      console.log(consignment?.serialNumber, 'Полученные настройки serialNumber ');
      if (res?.specifySeriesWhenCreatingShiftTask || consignment?.serialNumber) {
        consignmentKeys.set('serialNumber', {
          name: 'Серия',
          type: 'string',
          edited: true,
          required: true
        });
      } else {
        consignmentKeys.delete('serialNumber');
      }
    } catch (error) {
      console.error('Ошибка при получении настроек:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    dispatch(getPrintersAction());
  }, []);
  useEffect(() => {
    getLines();
  }, [consignmentId]);

  useEffect(() => {
    setCrumbs([
      '/consignments',
      `Задание ${consignment.taskNumber ? consignment?.taskNumber : ''}`
    ]);
    getGenralSettings();
  }, [consignment]);

  useEffect(() => {
    if (consignment && lines && consignment.lineNumber && lines.length) {
      const line = lines.find((item) => +item.value === +consignment.lineNumber);

      if (line) getProducts(line.id);
    }
  }, [consignment.lineNumber, lines]);

  useEffect(() => {
    if (consignmentId) {
      if (consignmentId === 'add') {
        setEditMode(null);
      } else {
        getConsignment(consignmentId);
      }
    }
  }, [consignmentId]);

  useEffect(() => {
    if (consignment.showSubsoilUseLicenses) {
      setConsignment((prev) => {
        const obj = { ...prev };

        if (consignment.subsoilUseLicenses && consignment.subsoilUseLicenses.length) {
          obj.licenseNumber = prev.subsoilUseLicenses[0].licenseNumber;
          obj.dateOfIssue = prev.subsoilUseLicenses[0].dateOfIssue;
        } else {
          obj.licenseNumber = '';
          obj.dateOfIssue = null;
        }
        return obj;
      });
    }
    if (consignment && !consignment?.showVsd) {
      delete consignment.vsd;
    }
  }, [consignment?.showSubsoilUseLicenses, consignment?.showVsd]);

  const updateConsignment = (name, value) => {
    let _value = value;
    if (consignmentKeys.has(name) && consignmentKeys.get(name).type === 'date' && value) {
      const timeZoneOffset = _value.getTimezoneOffset() * 60000;
      _value = Date.parse(value) - timeZoneOffset;
    }

    let newErrors = new Set(consignmentErrors);

    if (!validateField(name, value)) {
      newErrors.add(name);
    } else {
      newErrors.delete(name);
    }
    setConsignment((prev) => ({ ...prev, [name]: _value }));
    setConsignmentErrors(newErrors);
  };

  const saveConsignment = async () => {
    const onSuccess = () => {
      showAlert(
        editMode === 'edit' ? 'Редактирование задания' : 'Создание задания',
        editMode === 'edit' ? 'Задание успешно обновлено' : 'Задание успешно создано'
      );
    };

    let res;

    const isCorrect = validateForm(
      {
        taskNumber: consignment.taskNumber,
        product: consignment.product,
        productCreationDate: consignment.productCreationDate,
        productPackingDate: consignment.productPackingDate,
        lineNumber: consignment.lineNumber,
        ...(consignmentKeys.has('serialNumber') && { serialNumber: consignment.serialNumber })
      },
      consignmentErrors,
      setConsignmentErrors
    );
    if (isCorrect) {
      const payload = generateSubsoilUseLicensesField(consignment);
      if (consignmentId === 'add') {
        res = await consignmentsAPI.createConsignment(payload);
      } else {
        res = await consignmentsAPI.editConsignment(payload);
      }

      afterSending(res, onSuccess);
    }
  };

  const renderControl = (entry) => {
    const [key, value] = entry;
    if (consignmentKeys.has(key)) {
      const { name, type, required, edited } = consignmentKeys.get(key);

      switch (type) {
        case 'checkbox':
          return (
            <div className={s.infoRow}>
              <Checkbox
                label={name}
                disabled={!edited}
                indeterminate={false}
                checked={!!value}
                onChange={() => setConsignment((prev) => ({ ...prev, [key]: !prev[key] }))}
              />
            </div>
          );
        case 'date':
          return (
            <div className={clsx([s.infoRow, consignmentErrors.has(key) ? g.errorMessage : ''])}>
              <DatePicker
                className={s.date}
                firstDayOfWeek={DayOfWeek.Monday}
                label={name}
                disabled={!edited}
                isRequired={required}
                allowTextInput={true}
                formatDate={(date) => {
                  return !date ? null : moment(date).format('DD.MM.YYYY');
                }}
                strings={DayPickerStrings}
                value={!consignment[key] ? 0 : new Date(consignment[key])}
                onSelectDate={(value) => updateConsignment(key, value)}
              />
              {!!consignmentErrors.has(key) && (
                <span className={g.errorMessageText}>Обязательное поле</span>
              )}
            </div>
          );
        default:
          const valueString = value || value === 0 ? `${value}` : '';
          return (
            <div className={s.infoRow}>
              <TextField
                label={name}
                disabled={!edited}
                errorMessage={consignmentErrors.has(key) && 'Обязательное поле'}
                required={required}
                value={valueString}
                name={key}
                type={type}
                onChange={(e) => updateConsignment(e.target.name, e.target.value)}
              />
            </div>
          );
      }
    }
  };

  const setSelectedItem = (item, selectName) => {
    switch (selectName) {
      case 'product':
        const { value, name, gtin } = item;
        updateConsignment('product', { id: value, name, gtin });
        break;
      default:
        updateConsignment('lineNumber', item.value);
        break;
    }
  };
  const mainWidth = editMode === 'edit' ? '60%' : '80%';

  return (
    <div>
      {isAlertShowed && (
        <Alert title={alertTitle} text={alertText} onClose={() => history.push('/consignments')} />
      )}
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className={g.titleWrapper}>
            <h1 className={g.title}>{`Задание ${
              consignment.taskNumber ? consignment?.taskNumber : ''
            }`}</h1>
          </div>

          <GrayPanel className={s.panel}>
            <div className={s.inputsContainer}>
              <div className={s.mainInputs} style={{ width: mainWidth }}>
                <div
                  className={clsx([
                    s.infoRow,
                    !!consignmentErrors.has('lineNumber') ? g.errorMessage : ''
                  ])}
                >
                  {!!lines.length && (
                    <>
                      {/* <Dropdown
                      style={{ marginBottom: 25 }}
                      label={'Производственная линия'}
                      selectedKey={+consignment.lineNumber}
                      options={lines}
                      onChange={(e, item) => setSelectedItem(item, 'line')}
                    /> */}
                      <ComboSelect
                        options={lines}
                        onChange={(item) => setSelectedItem(item, 'line')}
                        label="Производственная линия"
                        placeholder={
                          consignment?.product?.name && consignment?.lineId && lines?.length
                            ? `${getLineName(lines, consignment?.lineId)}`
                            : ''
                        }
                        customFieldStyles={{ marginBottom: '25px' }}
                      />
                    </>
                  )}
                  {!!consignmentErrors.has('lineNumber') && (
                    <span className={g.errorMessageText}>Обязательное поле</span>
                  )}
                </div>
                <div
                  className={clsx([
                    s.infoRow,
                    !!consignmentErrors.has('lineNumber') ? g.errorMessage : ''
                  ])}
                >
                  {!!products && (
                    <>
                      {/* <Dropdown
                        label={'Продукт'}
                        className={clsx([
                          s.infoRow,
                          !!consignmentErrors.has('product') ? g.errorMessage : ''
                        ])}
                        style={{ marginBottom: 25 }}
                        selectedKey={consignment?.product?.id}
                        options={products}
                        onChange={(e, item) => setSelectedItem(item, 'product')}
                        disabled={!consignment.lineNumber || consignment.status !== 0}
                      /> */}
                      <ComboSelect
                        options={products}
                        label={'Продукт'}
                        customFieldStyles={{ marginBottom: '25px' }}
                        customClassNames={[
                          s.infoRow,
                          !!consignmentErrors.has('product') ? g.errorMessage : ''
                        ]}
                        isDisabled={!consignment.lineNumber || consignment.status !== 0}
                        onChange={(item) => setSelectedItem(item, 'product')}
                        placeholder={consignment?.product?.name || ''}
                      />
                    </>
                  )}
                  {!!consignmentErrors.has('product') && (
                    <span className={g.errorMessageText}>Обязательное поле</span>
                  )}
                </div>
                {/* 
                <Dropdown
                  style={{ marginBottom: 25 }}
                  label={'Статус'}
                  selectedKey={consignment.status}
                  options={consignmentsForDropdown}
                  disabled={true}
                  onChange={(e, item) => setSelectedItem(item, 'status')}
                /> */}

                <ComboSelect
                  options={consignmentsForDropdown}
                  label={'Статус'}
                  customFieldStyles={{ marginBottom: '25px' }}
                  isDisabled={true}
                  onChange={(item) => setSelectedItem(item, 'status')}
                  placeholder={
                    consignmentsForDropdown.find(
                      (statusObj) => statusObj.key.toString() === consignment.status.toString()
                    )?.text || consignment.status
                  }
                  isSearchable={false}
                />

                {consignment && (
                  <Stack tokens={{ childrenGap: 25 }} style={{ marginTop: 30 }}>
                    {Array.from(consignmentKeys.entries()).map(([key]) => {
                      if (consignment.hasOwnProperty(key)) {
                        return renderControl([key, consignment[key]]);
                      }
                    })}
                  </Stack>
                )}
              </div>
              {editMode === 'edit' && (
                <div className={s.rightPart}>
                  <PreliminaryPrintForm
                    productId={consignment?.product?.id}
                    id={consignmentId}
                    submitCallback={consignmentsAPI.preliminaryPrint}
                  />
                  <ExtraConsignmentFields consignmentInfo={consignment} />
                </div>
              )}
            </div>
          </GrayPanel>
          <section className={s.buttonsRow}>
            <div className={s.buttons}>
              <BottomButtons
                okText="Готово"
                okHandler={saveConsignment}
                closeHandler={() => history.push('/consignments')}
              />
              {/* <DeleteTestConsigment showButton={true} /> */}
            </div>
          </section>
        </>
      )}
    </div>
  );
};
