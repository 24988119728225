import { SearchBox } from 'office-ui-fabric-react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import settingsAPI from 'api/settingsAPI';
import ReportsAPI from '../../api/reportsAPI';
import g from '../../assets/scss/Main.module.scss';
import { Alert } from '../../components/Alert/Alert';
import { ReportsFilter } from '../../components/Filters/ReportsFilter/ReportsFilter';
import { FiltersPanel } from '../../components/FiltersPanel/FiltersPanel';
import { Pagination } from '../../components/Pagination/Pagination';
import { Table } from '../../components/Table/Table';
import { TableAction } from '../../components/TableAction/TableAction';
import { WaitingScreen } from '../../components/WaitingScreen/WaitingScreen';
import { sendIcon } from '../../constants';
import { isShipped } from '../../functions';
import { useAlert, usePaginationAndSearch, useSendRequest, useDataFilter } from '../../hooks';
import { getGlobalErrorText } from '../../redux/App/appSelectors';
import s from '../Products/Products.module.scss';
import st from './Reports.module.scss';
import { AggregationReportsTableRow } from './components/AggregationReportsTableRow';
import { EnteringReportsTableRow } from './components/EnteringReportsTableRow';
import { PrintingReportsTableRow } from './components/PrintingReportsTableRow';
import { WithdrwIntrnlConsupmt } from './components/WithdrwInternlConsupmtn';
import { SetsTableRow } from './components/SetsTableRow';
import { getTypesInfo } from './constants';
import { SyncStatus } from '../../components/SyncStatus/SyncStatus';
import ProductsAPI from '../../api/productsAPI';
import PrintReportModal from 'pages/Report/SendEnteringReportModal';
import { setPrintReports } from 'redux/App/appReducer';
import RejectionReportsTableRow from './components/RejectionReportsTableRow';
import { getShouldCheckExpDateForPrintRep } from '../../redux/App/appSelectors';
import { WriteOff } from './components/WriteOff';

export const Reports = ({ isOpenFilters, setIsOpenFilters, type, children }) => {
  // const { setCrumbs } = useCrumbsContext();
  const [loading, setLoading] = useState(false);
  const [reports, setReports] = useState([]);
  const [reportStatus, setreportStatus] = useState(null);
  const [syncStatus, setSyncStatus] = useState(null);
  const [selectedReports, setSelectedReports] = useState(new Set());
  const globalErrorText = useSelector(getGlobalErrorText);
  const currentWidth = window.screen.availWidth;
  const [query, setQuery] = useDataFilter('reports', type);
  const typesInfo = getTypesInfo(currentWidth);
  const [isPrintRepModal, setIsPrintModal] = useState(false);
  const dispatch = useDispatch();
  const shouldCheckExpDateForPrintRepFromRdx = useSelector(getShouldCheckExpDateForPrintRep);
  const [shouldCheckExpDateForPrintRep, setShouldCheckExpDateForPrintRep] = useState(false);

  useEffect(() => {
    if (globalErrorText) {
      setreportStatus(null);
    }
  }, [globalErrorText]);

  useEffect(() => {
    if (typeof shouldCheckExpDateForPrintRepFromRdx === 'boolean') {
      setShouldCheckExpDateForPrintRep(shouldCheckExpDateForPrintRepFromRdx);
    }
  }, [shouldCheckExpDateForPrintRepFromRdx]);

  const getReports = async (query = {}) => {
    setLoading(true);
    const getReportsTypes = {
      printing: (queryString) => ReportsAPI.getUtilisationReports(queryString),
      aggregation: (queryString) => ReportsAPI.getAggregationReports(queryString),
      entering: (queryString) => ReportsAPI.getRolloutReports(queryString),
      rejection: (queryString) => ReportsAPI.getRejectReports(queryString),
      sets: (queryString) => ReportsAPI.getSetsReport(queryString),
      withrdIntrnlConsmpt: (queryString) => ReportsAPI.getInternalWithdrowalReport(queryString),
      writeOff: (queryString) => ReportsAPI.getInternalWriteOffReport(queryString)
    };
    let res;
    res = await getReportsTypes[type](query);
    if (type === 'printing') {
      const genSettings = await settingsAPI.getGeneralSettings();
      if (
        genSettings &&
        'checkProductionAndExpirationDatesWhenSubmittingUtilisationReports' in genSettings
      )
        setShouldCheckExpDateForPrintRep(
          genSettings.checkProductionAndExpirationDatesWhenSubmittingUtilisationReports
        );
    }

    if (res) {
      if ('list' in res && 'quantity' in res) {
        setReports(res.list);
        console.log(res.list);
        setTotalItems(res.quantity);
        if (type === 'printing') {
          dispatch(setPrintReports(res.list));
        }
      }
    }
    setLoading(false);
  };

  const { alertTitle, alertText, isAlertShowed, showAlert, hideAlert } = useAlert();

  const { afterSending } = useSendRequest(setLoading);

  //============================= send reports =============================

  const sendReports = async () => {
    const sendReportsTypes = {
      // printing: (arrayArg) => ReportsAPI.sendUtilisationReports(arrayArg),
      printing: shouldCheckExpDateForPrintRep
        ? () => setIsPrintModal(true)
        : (arrayArg) => ReportsAPI.sendUtilisationReports(arrayArg),
      aggregation: (arrayArg) => ReportsAPI.sendAggregationReports(arrayArg),
      entering: (arrayArg) => ReportsAPI.sendRolloutReports(arrayArg),
      rejection: (arrayArg) => ReportsAPI.sendRejectReports(arrayArg),
      sets: (arrayArg) => ReportsAPI.sendSetsReports(arrayArg),
      withrdIntrnlConsmpt: (arrayArg) => ReportsAPI.sendInternalWithdrwlReports(arrayArg),
      writeOff: (arrayArg) => ReportsAPI.sendInternalWriteOffReports(arrayArg)
    };
    console.log(selectedReports);
    if (type === 'printing' && shouldCheckExpDateForPrintRep) {
      sendReportsTypes[type]();
    } else {
      setreportStatus(`$inProgress`);
      setSelectedReports(new Set());
      const res = await sendReportsTypes[type](Array.from(selectedReports));

      const onSuccess = () => {
        if (res?.errors?.length) {
          const errorString = res?.errors
            .reduce((acc, elem, i) => {
              return [...acc, [res?.notSendedRollouts[i], elem]];
            }, [])
            .map((item) => item.join(': '))
            .map((str) => <p style={{ margin: '5px 0' }}>{str}</p>);

          showAlert('Ошибки отправки отчетов', errorString);
        } else {
          showAlert('Отправка отчётов', 'Отчёты успешно отправлены');
        }
        getReports(query);
        setreportStatus('success');
      };

      const onError = async () => {
        setreportStatus('error');
      };

      afterSending(res, onSuccess, onError);
    }
  };

  //============================= send reports =============================

  const syncProducts = async (type) => {
    setSyncStatus(`${type}InProgress`);
    let syncRes;

    if (type === 'suz') {
      syncRes = await ProductsAPI.setGetReportStatus();
    }

    if (syncRes.statusCode === 200) {
      await getReports(query);
      setSyncStatus('success');
    } else {
      setSyncStatus('error');
    }
  };
  const onSelectReport = (report) => {
    if (!isShipped(report.status)) {
      const newSelectedReports = new Set(selectedReports);

      if (selectedReports.has(report.id)) {
        newSelectedReports.delete(report.id);
      } else {
        newSelectedReports.add(report.id);
      }

      setSelectedReports(newSelectedReports);
    }
  };

  const onSubmitPrintReport = async (data) => {
    const resData = data.map((rep) => ({
      id: rep.id,
      productCreationDate: rep.productCreationDate,
      expirationDate: rep.expirationDate
    }));
    await ReportsAPI.senPrinRepsParams(resData);
    await getReports(query);
  };

  const { onPageChanged, onSearchTermChanged, totalPages, setTotalItems, currentPage } =
    usePaginationAndSearch({
      callback: getReports,
      query,
      setQuery
    });

  const showButton = false;

  const showMessage = () => {
    const {
      minCreationDate,
      maxCreationDate,
      minSendingDate,
      maxSendingDate,
      minProductCreationDate,
      maxProductCreationDate
    } = query;
    return [
      minCreationDate,
      maxCreationDate,
      minSendingDate,
      maxSendingDate,
      minProductCreationDate,
      maxProductCreationDate
    ].some(Boolean);
  };

  return (
    <>
      {isAlertShowed && (
        <Alert
          title={alertTitle}
          text={alertText}
          onClose={() => {
            hideAlert();
          }}
        />
      )}
      {isPrintRepModal ? (
        <PrintReportModal
          isOpen={isPrintRepModal}
          setIsOpen={setIsPrintModal}
          reports={reports.filter((r) => selectedReports.has(r.id))}
          onDismiss={() => {
            setIsPrintModal(false);
            setSelectedReports(new Set());
            
          }}
          onSubmit={onSubmitPrintReport}
        />
      ) : null}
      <div>
        <div className={g.titleWrapper}>
          <h1 className={g.title}>{`Отчёты ${typesInfo[type]?.title}`}</h1>
        </div>

        <div className={`${g.header} ${s.reportsHeader}`}>
          <span className={g.headerTitle} />
          {type !== 'sets' && (
            <SearchBox
              className={g.search}
              value={query.searchValue}
              placeholder="Поиск по коду отчёта, названию товара, gtin или номеру задания"
              onClear={() => onSearchTermChanged('')}
              onSearch={(newValue) => onSearchTermChanged(newValue)}
            />
          )}
        </div>
        <Table
          headerItems={typesInfo[type].tableHeader}
          maxHeight="60vh"
          loading={loading}
          isReport={true}
        >
          {type === 'printing' &&
            reports.map((report) => (
              <PrintingReportsTableRow
                report={report}
                key={report.id}
                onSelectReport={onSelectReport}
                selectedReports={selectedReports}
              />
            ))}
          {type === 'aggregation' &&
            reports.map((report) => (
              <AggregationReportsTableRow
                report={report}
                key={report.id}
                onSelectReport={onSelectReport}
                selectedReports={selectedReports}
              />
            ))}
          {type === 'entering' &&
            reports.map((report) => (
              <EnteringReportsTableRow
                report={report}
                key={report.id}
                onSelectReport={onSelectReport}
                selectedReports={selectedReports}
              />
            ))}
          {type === 'sets' &&
            reports.map((report) => (
              <SetsTableRow
                report={report}
                key={report.id}
                onSelectReport={onSelectReport}
                selectedReports={selectedReports}
              />
            ))}
          {type === 'rejection' &&
            reports.map((report) => (
              <RejectionReportsTableRow
                report={report}
                key={report.id}
                onSelectReport={onSelectReport}
                selectedReports={selectedReports}
              />
            ))}
          {type === 'withrdIntrnlConsmpt' &&
            reports.map((report) => (
              <WithdrwIntrnlConsupmt
                report={report}
                key={report.id}
                onSelectReport={onSelectReport}
                selectedReports={selectedReports}
              />
            ))}
          {type === 'writeOff' &&
            reports.map((report) => (
              <WriteOff
                report={report}
                key={report.id}
                onSelectReport={onSelectReport}
                selectedReports={selectedReports}
              />
            ))}
        </Table>
        <div className={g.footer}>
          {totalPages > 1 && (
            <Pagination
              pageCount={totalPages}
              onPageChange={onPageChanged}
              selectedPage={currentPage}
            />
          )}
          <TableAction
            iconName={sendIcon}
            onClick={sendReports}
            text="Отправить выбранные"
            isButton={true}
            disabled={!selectedReports.size}
          />
        </div>
        <div className={s.syncButtons}>
          {showButton && (
            <TableAction
              iconName={'Sync'}
              addStyles={{ width: '18.75rem', marginTop: '10px' }}
              disabled={syncStatus === 'suzInProgress'}
              isButton={true}
              onClick={() => syncProducts('suz')}
              text="Синхронизировать СУЗ"
              type={'accent'}
            />
          )}
        </div>

        <FiltersPanel
          isOpenFilters={isOpenFilters}
          setIsOpenFilters={setIsOpenFilters}
          query={query}
          setQuery={setQuery}
        >
          <ReportsFilter type={type} />
        </FiltersPanel>
      </div>
      {reportStatus?.includes('inProgress') && (
        <WaitingScreen
          title={'Выполняется отправка отчётов'}
          text={
            'Пожалуйста, подождите. Это может занять несколько минут. Не закрывайте эту страницу.'
          }
        />
      )}
      {['success', 'error'].includes(syncStatus) && (
        <SyncStatus syncStatus={syncStatus} hide={() => setSyncStatus(null)} />
      )}
    </>
  );
};
